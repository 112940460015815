import { TextField, TextFieldProps } from "@mui/material";
import { FieldError, useFormContext } from "react-hook-form";

type FormFieldProps = {
  name: string;
  error?: FieldError;
} & Omit<TextFieldProps, "variant" | "error" | "name">;

export const TextFormField = ({
  type,
  label,
  name,
  error,
  ...rest
}: FormFieldProps) => {
  const formContext = useFormContext();
  return (
    <TextField
      id={name}
      type={type}
      label={label}
      variant="outlined"
      {...formContext.register(name)}
      error={!!error}
      helperText={error?.message}
      {...rest}
    />
  );
};
